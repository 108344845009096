import { query } from './query';
import { isDefined, isObject } from './types';

/**
 * @param {object} object
 * @returns
 */
export function isObjectEmpty(object) {
  for (let prop in object) {
    if (Object.hasOwnProperty.call(object, prop)) {
      return false;
    }
  }
  return true;
}

/**
 * @template T
 * @param {T[]} arr
 * @returns {T[]}
 */
export function removeEmptyElementsFromTheArrayRight(arr) {
  const newArr = arr.slice();

  while (newArr.length > 0) {
    const lastElement = newArr[newArr.length - 1];

    if (lastElement === '' || (isObject(lastElement) && isObjectEmpty(lastElement))) {
      newArr.pop();
    } else {
      return newArr;
    }
  }

  return newArr;
}

/**
 *
 * @returns {number}
 */
export function getCurrentTimestampInSeconds() {
  return Math.floor(new Date().getTime() / 1000);
}

/**
 * @param {Element} sourceElement
 * @returns {HTMLAreaElement | HTMLLinkElement | Element}
 */
export function getSourceElement(sourceElement) {
  let parentElement;

  parentElement = sourceElement.parentNode;
  while (
    parentElement !== null &&
    /* buggy IE5.5 */
    isDefined(parentElement)
  ) {
    if (query.isLinkElement(sourceElement)) {
      break;
    }
    sourceElement = parentElement;
    parentElement = sourceElement.parentNode;
  }

  return sourceElement;
}
