import { sha1 } from '../sha1';

/**
 * Generates a browser-specific ID based on the user agent, platform, and browser features.
 * @param {Object.<string, string>} browserFeatures
 * @param {function(string): string} [hash]
 * @returns {string}
 */
export function generateBrowserSpecificId(browserFeatures, hash) {
  if (!hash) {
    hash = sha1;
  }
  return hash(
    (navigator.userAgent || '') + (navigator.platform || '') + JSON.stringify(browserFeatures)
  ).slice(0, 6);
}
