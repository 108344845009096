import { generateBrowserSpecificId } from '../../utils/crypto';
import { domainFixup } from '../../utils/fixup';
import { getCurrentTimestampInSeconds } from '../../utils/helpers';
import { query } from '../../utils/query';
import {
  addUrlParameter,
  getHostName,
  getPathName,
  getUrlParameter,
  isSameHost,
  parseHref,
  removeUrlParameter,
  startsUrlWith,
} from '../../utils/url';
import { isSiteHostPath } from '../OutlinkTracking';

/**
 *
 * @param {Object.<string,string>} browserFeatures
 * @returns {string}
 */
export function makeCrossDomainDeviceId(browserFeatures) {
  const timestamp = getCurrentTimestampInSeconds();
  const browserId = generateBrowserSpecificId(browserFeatures);
  const deviceId = String(timestamp) + browserId;

  return deviceId;
}

/**
 *
 * @param {string} deviceIdFromUrl
 * @param {number} visitorIdUrlParameterTimeoutInSeconds
 * @param {Object.<string,string>} browserFeatures
 * @returns {boolean}
 */
export function isSameCrossDomainDevice(
  deviceIdFromUrl,
  visitorIdUrlParameterTimeoutInSeconds,
  browserFeatures
) {
  deviceIdFromUrl = String(deviceIdFromUrl);

  const thisBrowserId = generateBrowserSpecificId(browserFeatures);
  const lengthBrowserId = thisBrowserId.length;

  const browserIdInUrl = deviceIdFromUrl.substr(-1 * lengthBrowserId, lengthBrowserId);
  const timestampInUrl = parseInt(
    deviceIdFromUrl.substr(0, deviceIdFromUrl.length - lengthBrowserId),
    10
  );

  if (timestampInUrl && browserIdInUrl && browserIdInUrl === thisBrowserId) {
    // we only reuse visitorId when used on same device / browser

    const currentTimestampInSeconds = getCurrentTimestampInSeconds();

    if (visitorIdUrlParameterTimeoutInSeconds <= 0) {
      return true;
    }
    if (
      currentTimestampInSeconds >= timestampInUrl &&
      currentTimestampInSeconds <= timestampInUrl + visitorIdUrlParameterTimeoutInSeconds
    ) {
      // we only use visitorId if it was generated max 180 seconds ago
      return true;
    }
  }

  return false;
}

/**
 *
 * @param {string} url
 * @param {Object.<string,string>} browserFeatures
 * @param {Object} config
 * @param {boolean} config.crossDomainTrackingEnabled
 * @param {string} config.configVisitorIdUrlParameter
 * @param {number} config.configVisitorIdUrlParameterTimeoutInSeconds
 * @param {function(string,string): string} [crossDomainTrackingLinkVisitorIdGetter]
 * @returns {string}
 */
export function getVisitorIdFromUrl(
  url,
  browserFeatures,
  config,
  crossDomainTrackingLinkVisitorIdGetter
) {
  if (!config.crossDomainTrackingEnabled) {
    return '';
  }

  if (!crossDomainTrackingLinkVisitorIdGetter) {
    crossDomainTrackingLinkVisitorIdGetter = getUrlParameter;
  }

  // problem different timezone or when the time on the computer is not set correctly it may re-use
  // the same visitorId again. therefore we also have a factor like hashed user agent to reduce possible
  // activation of a visitorId on other device
  let visitorIdParam = crossDomainTrackingLinkVisitorIdGetter(
    url,
    config.configVisitorIdUrlParameter
  );

  if (!visitorIdParam) {
    return '';
  }

  visitorIdParam = String(visitorIdParam);

  const pattern = new RegExp('^[a-zA-Z0-9]+$');

  if (visitorIdParam.length === 16 && pattern.test(visitorIdParam)) {
    return visitorIdParam;
  }
  if (visitorIdParam.length === 32 && pattern.test(visitorIdParam)) {
    const visitorDevice = visitorIdParam.substr(16, 32);

    if (
      isSameCrossDomainDevice(
        visitorDevice,
        config.configVisitorIdUrlParameterTimeoutInSeconds,
        browserFeatures
      )
    ) {
      const visitorId = visitorIdParam.substr(0, 16);
      return visitorId;
    }
  }

  return '';
}

/**
 *
 * @param {Node} element
 * @param {Object} query
 * @param {function(Node,string): boolean} query.hasNodeAttribute
 * @param {function(Node,string): string|undefined} query.getAttributeValueFromNode
 * @param {function(Node,string,string): void} query.setAnyAttribute
 * @param {Object} config
 * @param {string} config.configVisitorIdUrlParameter
 * @param {string} config.configTrackerUrl
 * @param {Object.<string,string>} config.browserFeatures
 * @param {function(string,string,string): string} [config.crossDomainTrackingLinkDecorator]
 * @returns {void}
 */
export function replaceHrefForCrossDomainLink(element, query, visitorId, config) {
  if (!element) {
    return;
  }

  if (!query.hasNodeAttribute(element, 'href')) {
    return;
  }

  let link = query.getAttributeValueFromNode(element, 'href');

  if (!link || startsUrlWith(link, config.trackerUrl)) {
    return;
  }

  const _crossDomainTrackingLinkDecorator =
    config.crossDomainTrackingLinkDecorator || crossDomainTrackingLinkDecorator;

  link = _crossDomainTrackingLinkDecorator(
    link,
    getCrossDomainVisitorId(visitorId, config.browserFeatures),
    config.configVisitorIdUrlParameter
  );

  if (link !== null) {
    query.setAnyAttribute(element, 'href', link);
  }
}

/**
 * URL decorator for cross domain tracking. It adds visitor ID as a URL parameter.
 * @param {string} url link URL
 * @param {string} value value of cross domain visitor ID
 * @param {string} name name of cross domain visitor ID parameter
 * @return {string} Decorated URL
 */
export function crossDomainTrackingLinkDecorator(url, value, name) {
  // we need to remove the parameter and add it again if needed to make sure we have latest timestamp
  // and visitorId (eg userId might be set etc)
  const result = removeUrlParameter(url, name);

  return addUrlParameter(result, name, value);
}

/**
 * @param {string} visitorId
 * @param {Object.<string,string>} browserFeatures
 * @returns {string}
 */
export function getCrossDomainVisitorId(visitorId, browserFeatures) {
  return visitorId + makeCrossDomainDeviceId(browserFeatures);
}

/**
 *
 * @param {Node} element
 * @param {Object} content
 * @param {function(string): string} content.toAbsoluteUrl
 * @param {Object} config
 * @param {Array.<string>} config.hostAliases
 * @param {string} config.domainAlias
 * @returns {boolean}
 */
export function isLinkToDifferentDomainButSamePiwikWebsite(element, content, config) {
  let targetLink = query.getAttributeValueFromNode(element, 'href');

  if (!targetLink) {
    return false;
  }

  targetLink = String(targetLink);

  const isOutlink =
    targetLink.indexOf('//') === 0 ||
    targetLink.indexOf('http://') === 0 ||
    targetLink.indexOf('https://') === 0;

  if (!isOutlink) {
    return false;
  }

  const parsedHref = parseHref(targetLink);

  const originalSourcePath = parsedHref.pathname || getPathName(targetLink, content);
  const originalSourceHostName = (parsedHref.hostname || getHostName(targetLink)).toLowerCase();

  if (isSiteHostPath(originalSourceHostName, originalSourcePath, config.hostAliases, content)) {
    // we could also check against config cookie domain but this would require that other website
    // sets actually same cookie domain and we cannot rely on it.
    if (!isSameHost(config.domainAlias, domainFixup(originalSourceHostName))) {
      return true;
    }

    return false;
  }

  return false;
}
