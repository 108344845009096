import { transformIfAMPCookieFactory, composeCookie } from './cookiesHelpers';
import { isDefined } from '../../utils/types';

/**
 * @typedef {Object} CookiesConfig
 * @property {boolean} cookiesDisabled
 * @property {string} cookieNamePrefix
 * @property {number | string} trackerSiteId
 * @property {string} domainHash
 * @property {number} [msToExpire]
 * @property {string} [path]
 * @property {string} [domain]
 * @property {boolean} [isSecure]
 */

/**
 * @param {Object} dependencies
 * @param {Document} dependencies.documentAlias
 * @param {Navigator} dependencies.navigatorAlias
 * @param {function(string): string} dependencies.decodeWrapper
 * @param {function(string | number | boolean): string} dependencies.encodeWrapper
 * @param {function(): CookiesConfig} dependencies.getCookiesConfig
 */
export function CookiesService(dependencies) {
  return {
    /**
     * @param {string} id
     * @returns {string}
     */
    transformIfAMPCookie: transformIfAMPCookieFactory(),

    /**
     * Get cookie name with prefix and domain hash
     * @param {string} baseName
     * @returns {string}
     */
    getCookieName(baseName) {
      // NOTE: If the cookie name is changed, we must also update the PiwikTracker.php which
      // will attempt to discover first party cookies. eg. See the PHP Client method getVisitorId()
      return (
        dependencies.getCookiesConfig().cookieNamePrefix +
        baseName +
        '.' +
        dependencies.getCookiesConfig().trackerSiteId +
        '.' +
        dependencies.getCookiesConfig().domainHash
      );
    },
    /**
     * @param {string} cookieName
     * @returns {string | number}
     */
    getCookie(cookieName) {
      if (dependencies.getCookiesConfig().cookiesDisabled) {
        return 0;
      }

      const cookiePattern = new RegExp('(^|;)[ ]*' + cookieName + '=([^;]*)');
      const cookieMatch = cookiePattern.exec(dependencies.documentAlias.cookie);
      return cookieMatch ? dependencies.decodeWrapper(cookieMatch[2]) || 0 : 0;
    },
    /**
     * @param {string} cookieName
     * @param {string} value
     * @param {Object} config
     * @param {number} [config.msToExpire]
     * @param {string} [config.path]
     * @returns {void}
     */
    setCookie(cookieName, value, config) {
      const mergedConfig = dependencies.getCookiesConfig();
      mergedConfig.msToExpire = config
        ? config.msToExpire || mergedConfig.msToExpire
        : mergedConfig.msToExpire;
      mergedConfig.path = config ? config.path || mergedConfig.path : mergedConfig.path;

      if (mergedConfig.cookiesDisabled) {
        return;
      }

      dependencies.documentAlias.cookie = composeCookie(
        cookieName,
        dependencies.encodeWrapper(value),
        mergedConfig
      );
    },
    /**
     * @param {string} cookieName
     * @returns {void}
     */
    deleteCookie(cookieName) {
      this.setCookie(cookieName, '', { msToExpire: -86400 });
    },
    /**
     * Does browser have cookies enabled (for this site)?
     * @returns {'1' | '0'}
     */
    hasCookies() {
      if (dependencies.getCookiesConfig().cookiesDisabled) {
        return '0';
      }

      if (!isDefined(dependencies.navigatorAlias.cookieEnabled)) {
        const testCookieName = this.getCookieName('_pk_testcookie');
        this.setCookie(testCookieName, '1');

        const result = this.getCookie(testCookieName) === '1' ? '1' : '0';
        this.deleteCookie(testCookieName);
        return result;
      }

      return dependencies.navigatorAlias.cookieEnabled ? '1' : '0';
    },
  };
}
