import { logConsoleError } from '../../utils/logger';

/**
 * @param {{onResolve: () => void}} callbacks
 * @returns {{getState: ()=> {state: "available", hints: []} | {state: "unavailable"} | {state: "loading"}}}
 */
export function ClientHintsProvider(callbacks) {
  /**
   * @type {("loading" | "available" | "unavailable")}
   */
  let state = 'loading';
  let clientHints = undefined;

  if (!navigator.userAgentData?.getHighEntropyValues) {
    state = 'unavailable';
  } else {
    navigator.userAgentData
      .getHighEntropyValues([
        'model',
        'brands',
        'platform',
        'platformVersion',
        'fullVersionList',

        // The following parameters are returned by the browser but are not supported at the moment.
        // 'wow64',
        // 'mobile',
        // 'bitness',
        // 'formFactor',
        // 'architecture',
        // 'uaFullVersion',
      ])
      .then((data) => {
        clientHints = data;
        state = 'available';
      })
      .catch((error) => {
        logConsoleError(error);
        state = 'unavailable';
      })
      .finally(() => {
        callbacks.onResolve();
      });
  }

  return {
    getState: () => {
      if (state === 'available') {
        return {
          state: 'available',
          hints: clientHints,
        };
      }

      return {
        state,
      };
    },
  };
}
