import { getUrlParameter, getHostName } from '../url';

import { isString, isDefined } from '../types';

/**
 * @param {string} domain
 * @returns {string}
 */
export function domainFixup(domain) {
  let domainLength = domain.length;

  // remove trailing '.'
  if (domain.charAt(--domainLength) === '.') {
    domain = domain.slice(0, domainLength);
  }

  // remove leading '*'
  if (domain.slice(0, 2) === '*.') {
    domain = domain.slice(1);
  }

  if (domain.indexOf('/') !== -1) {
    domain = domain.substr(0, domain.indexOf('/'));
  }

  return domain;
}

/**
 * Fix-up URL when page rendered from search engine cache or translated page
 *
 * @param {string} hostName
 * @param {string} href
 * @param {string} referrer
 * @returns {[hostname: string, href: string, referrer: string]}
 */
export function urlFixup(hostName, href, referrer) {
  if (!hostName) {
    hostName = '';
  }

  if (!href) {
    href = '';
  }

  if (hostName === 'translate.googleusercontent.com') {
    // Google
    if (referrer === '') {
      referrer = href;
    }

    href = getUrlParameter(href, 'u');
    hostName = getHostName(href);
  } else if (
    hostName === 'cc.bingj.com' || // Bing
    hostName === 'webcache.googleusercontent.com' || // Google
    hostName.slice(0, 5) === '74.6.'
  ) {
    // Yahoo (via Inktomi 74.6.0.0/16)
    href = document.links[0].href;
    hostName = getHostName(href);
  }

  return [hostName, href, referrer];
}

/**
 * Title fixup
 * @param {string} [title]
 * @returns {string}
 */
export function titleFixup(title) {
  title = title && title.text ? title.text : title;

  if (!isString(title)) {
    const tmp = document.getElementsByTagName('title');

    if (tmp && isDefined(tmp[0])) {
      title = tmp[0].text;
    }
  }

  return title;
}
