/**
 * Construct regular expression of classes
 * @param {string[]} configClasses
 * @param {string} defaultClass
 */
export function getClassesRegExp(configClasses, defaultClass) {
  let classesRegExp = '(^| )(piwik[_-]' + defaultClass;

  if (configClasses) {
    for (let i = 0; i < configClasses.length; i++) {
      classesRegExp += '|' + configClasses[i];
    }
  }

  classesRegExp += ')( |$)';

  return new RegExp(classesRegExp);
}
