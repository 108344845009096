import { executePluginMethod } from '../plugins';

/**
 * Handle beforeunload event
 *
 * Subject to Safari's "Runaway JavaScript Timer" and
 * Chrome V8 extension that terminates JS that exhibits
 * "slow unload", i.e., calling getTime() > 1000 times
 * @param {Object} trackers
 * @param {Array.<{trackHeartBeat:function({lastHeartbeat: boolean}): void}>} trackers.asyncTrackers
 * @param {Array.<{trackHeartBeat:function({lastHeartbeat: boolean}): void}>} trackers.syncTrackers
 * @param {Object.<string, Object.<string, Function>>} plugins
 * @param {number} [expireDateTime]
 * @returns {void}
 */
export function beforeUnloadHandler(trackers, plugins, expireDateTime) {
  for (let i = 0; i < trackers.asyncTrackers.length; i += 1) {
    trackers.asyncTrackers[i].trackHeartBeat({ lastHeartbeat: true });
  }

  for (let i = 0; i < trackers.syncTrackers.length; i += 1) {
    trackers.syncTrackers[i].trackHeartBeat({ lastHeartbeat: true });
  }

  executePluginMethod(plugins, 'unload');
  /*
   * Delay/pause (blocks UI)
   */
  if (expireDateTime) {
    // the things we do for backwards compatibility...
    // in ECMA-262 5th ed., we could simply use:
    //     while (Date.now() < expireDateTime) { }

    /**
     * @type {Date}
     */
    let now;
    do {
      now = new Date();
    } while (now.getTime() < expireDateTime);
  }
}
