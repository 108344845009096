/**
 * Call plugin hook methods
 * @param {Object.<string, Object.<string, Function>>} plugins
 * @param {string} methodName
 * @param {Object} [params]
 * @param {Function} [callback]
 * @returns {string}
 */
export function executePluginMethod(plugins, methodName, params, callback) {
  if (!methodName) {
    return '';
  }

  let result = '';

  for (let i in plugins) {
    if (Object.prototype.hasOwnProperty.call(plugins, i)) {
      const isFunction = plugins[i] && 'function' === typeof plugins[i][methodName];

      if (isFunction) {
        const pluginMethod = plugins[i][methodName];
        const value = pluginMethod(params || {}, callback);

        if (value) {
          result += value;
        }
      }
    }
  }

  return result;
}
