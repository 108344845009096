//@ts-check
import { logConsoleError } from '../logger';

/** @import { EcommerceOptions } from '../../types.js' */

/**
 * @param {EcommerceOptions} options
 * @param {string} callerFunctionName
 * @returns {boolean}
 */
export function validateEcommerceOptionsParameter(options, callerFunctionName) {
  if (typeof options === 'undefined') {
    return true;
  }

  if (typeof options !== 'object' || options === null) {
    logConsoleError(
      `[${callerFunctionName}]: parameter 'options' have to be of type object or undefined. Received: ${JSON.stringify(options)}`
    );

    return false;
  }

  if (typeof options.currencyCode === 'string' || typeof options.currencyCode === 'undefined') {
    return true;
  }

  logConsoleError(
    `[${callerFunctionName}]: parameter 'options.currencyCode' has to be of type string or undefined Received: ${JSON.stringify(options.currencyCode)}`
  );

  return false;
}
