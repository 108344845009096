import { logConsoleError } from '../../utils/logger';
import { DEFAULT_HEARTBEAT_TIMINGS } from './constants';
import { validateHeartbeatTimersParam } from './utils';

export const PingLevel = {
  PeriodicHeartbeat: 1,
  LastHeartbeat: 2,
  BlurHeartbeat: 3,
  Deanonymize: 4,
  PerformanceMetrics: 5,
  OnDemand: 6,
};

/**
 * @param {{onPing: (pingLevel: number) => void}} callbacks
 * @returns
 */
export function HeartBeat(callbacks) {
  let isEnabled = false;
  // timer could be paused due to tab switch
  let isPaused = false;

  // default to true, since we want to ignore the first 'focus' event
  let isWindowInFocus = true;
  /**
   * @type number[]
   */
  let timings = DEFAULT_HEARTBEAT_TIMINGS;
  /**
   * @type number | undefined
   */
  let timeoutId;
  let currentBeat = 0;

  const heartBeatTimerUp = () => {
    const timeoutSec = timings[currentBeat];
    if (timeoutSec === undefined) {
      timeoutId = undefined;
      return;
    }

    timeoutId = setTimeout(() => {
      if (isWindowInFocus) {
        callbacks.onPing(PingLevel.PeriodicHeartbeat);
      }

      currentBeat++;

      heartBeatTimerUp();
    }, timeoutSec * 1000);
  };

  const onFocus = () => {
    if (isPaused) {
      heartBeatTimerUp();
      isPaused = false;
    }

    if (isWindowInFocus) {
      return;
    }

    isWindowInFocus = true;
    callbacks.onPing(PingLevel.PeriodicHeartbeat);
  };

  const onBlur = () => {
    isWindowInFocus = false;
    callbacks.onPing(PingLevel.BlurHeartbeat);
  };

  /**
   *
   * @param {number[]} beatTimings
   * @returns {{enable:() => void}}
   */
  function setTimings(beatTimings) {
    const validationResult = validateHeartbeatTimersParam(beatTimings);
    if (!validationResult.isValid) {
      logConsoleError(validationResult.error);

      return this;
    }
    timings = beatTimings;

    return this;
  }

  return {
    enable: () => {
      isEnabled = true;

      window.addEventListener('focus', onFocus);
      window.addEventListener('blur', onBlur);

      if (document.hasFocus()) {
        heartBeatTimerUp();
        return;
      }

      isPaused = true;
    },

    disable: () => {
      isEnabled = false;
      isPaused = false;
      clearTimeout(timeoutId);
      timeoutId = undefined;
      currentBeat = 0;

      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    },

    setTimings,

    isEnabled: () => isEnabled,
  };
}
