/**
 * @param {string} str
 * @param {string} prefix
 */
export function stringStartsWith(str, prefix) {
  str = String(str);
  return str.lastIndexOf(prefix, 0) === 0;
}

/**
 * @param {string} str
 * @param {string} suffix
 */
export function stringEndsWith(str, suffix) {
  str = String(str);
  return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

/**
 * @param {string} prefix
 * @param {string} str
 * @return {string}
 */
export function addPrefixToString(prefix, str) {
  str = String(str);
  prefix = String(prefix);
  if (prefix !== '') {
    return prefix + str.charAt(0).toUpperCase() + str.slice(1);
  }

  return str;
}

/**
 *
 * @param {string} str
 * @param {string} needle
 * @returns {boolean}
 */
export function stringContains(str, needle) {
  str = String(str);
  return str.indexOf(needle) !== -1;
}

/**
 *
 * @param {string} str
 * @param {number} numCharactersToRemove
 * @returns {string}
 */
export function removeCharactersFromEndOfString(str, numCharactersToRemove) {
  str = String(str);
  return str.substr(0, str.length - numCharactersToRemove);
}
