/**
 * Cross-browser helper function to add event handler
 *
 * @param {HTMLElement} element
 * @param {string} eventType
 * @param {EventListener} eventHandler
 * @param {boolean} useCapture
 */
export function addEventListenerWrapper(element, eventType, eventHandler, useCapture) {
  if (element.addEventListener) {
    element.addEventListener(eventType, eventHandler, useCapture);

    return true;
  }

  if (element.attachEvent) {
    return element.attachEvent('on' + eventType, eventHandler);
  }

  element['on' + eventType] = eventHandler;
}
