import { removeCharactersFromEndOfString, stringContains, stringEndsWith } from '../string';

/**
 * @param {string} trackerUrl
 * @param {string} apiUrl
 * @param {Object} content
 * @param {function(string): string} content.toAbsoluteUrl
 * @returns {string}
 */
export function getPiwikUrlForOverlay(trackerUrl, apiUrl, content) {
  if (apiUrl) {
    return apiUrl;
  }

  trackerUrl = content.toAbsoluteUrl(trackerUrl);

  // if eg http://www.example.com/js/tracker.php?version=232323 => http://www.example.com/js/tracker.php
  if (stringContains(trackerUrl, '?')) {
    const posQuery = trackerUrl.indexOf('?');
    trackerUrl = trackerUrl.slice(0, posQuery);
  }

  if (stringEndsWith(trackerUrl, 'ppms.php')) {
    // if eg without domain or path "ppms.php" => ''
    trackerUrl = removeCharactersFromEndOfString(trackerUrl, 'ppms.php'.length);
  } else if (stringEndsWith(trackerUrl, '.php')) {
    // if eg http://www.example.com/js/ppms.php => http://www.example.com/js/
    // or if eg http://www.example.com/tracker.php => http://www.example.com/
    const lastSlash = trackerUrl.lastIndexOf('/');
    const includeLastSlash = 1;
    trackerUrl = trackerUrl.slice(0, lastSlash + includeLastSlash);
  }

  // if eg http://www.example.com/js/ => http://www.example.com/ (when not minified Piwik JS loaded)
  if (stringEndsWith(trackerUrl, '/js/')) {
    trackerUrl = removeCharactersFromEndOfString(trackerUrl, 'js/'.length);
  }

  // http://www.example.com/
  return trackerUrl;
}
