import { domainFixup } from '../../utils/fixup';
import { getSourceElement } from '../../utils/helpers';
import { query } from '../../utils/query';
import { stringEndsWith, stringStartsWith } from '../../utils/string';
import { isDefined } from '../../utils/types';
import { getHostName, getPathName, isSameHost, parseHref, startsUrlWith } from '../../utils/url';
import { getClassesRegExp } from './utils';

const mobileURIRegex = new RegExp('^[a-z]+://.+$', 'i');

/**
 * @param {string[]} domains
 */
export function getMobileAppURIsFromDomains(domains) {
  const mobileAppURIs = [];

  for (let i = 0; i < domains.length; i++) {
    const uri = domains[i];

    if (mobileURIRegex.test(uri)) {
      mobileAppURIs.push(uri);
    }
  }

  return mobileAppURIs;
}

/**
 * @param {HTMLAnchorElement} anchor
 * @param {string[]} domains
 */
export function isLinkToMobileAppInDomains(anchor, domains) {
  const link = query.getAttributeValueFromNode(anchor, 'href');
  if (!link) return false;

  const hostAndPath = link.split('://');
  if (hostAndPath.length !== 2) return false;

  const host = hostAndPath[0].toLowerCase();
  const path = removeParameters(hostAndPath[1]);

  const mobileAppURIs = getMobileAppURIsFromDomains(domains);

  for (let i = 0; i < mobileAppURIs.length; i++) {
    const uri = mobileAppURIs[i];

    const hostAndPathAlias = uri.split('://');
    if (hostAndPathAlias.length !== 2) return false;

    const hostAlias = hostAndPathAlias[0].toLowerCase();
    const pathAlias = hostAndPathAlias[1];

    if (host === hostAlias && isSitePath(path, pathAlias)) {
      return true;
    }
  }

  return false;
}
/**
 * @param {string} path
 * @param {string} pathAlias
 */
export function isSitePath(path, pathAlias) {
  if (!stringStartsWith(pathAlias, '/')) {
    pathAlias = '/' + pathAlias;
  }

  if (!stringStartsWith(path, '/')) {
    path = '/' + path;
  }

  let matchesAnyPath = pathAlias === '/' || pathAlias === '/*';

  if (matchesAnyPath) {
    return true;
  }

  if (path === pathAlias) {
    return true;
  }

  pathAlias = String(pathAlias).toLowerCase();
  path = String(path).toLowerCase();

  // wildcard path support
  if (stringEndsWith(pathAlias, '*')) {
    // remove the final '*' before comparing
    pathAlias = pathAlias.slice(0, -1);

    // Note: this is almost duplicated from just few lines above
    matchesAnyPath = !pathAlias || pathAlias === '/';

    if (matchesAnyPath) {
      return true;
    }

    if (path === pathAlias) {
      return true;
    }

    // wildcard match
    return path.indexOf(pathAlias) === 0;
  }

  // we need to append slashes so /foobarbaz won't match a site /foobar
  if (!stringEndsWith(path, '/')) {
    path += '/';
  }

  if (!stringEndsWith(pathAlias, '/')) {
    pathAlias += '/';
  }

  return path.indexOf(pathAlias) === 0;
}

/**
 * @param {string} path
 */
function removeParameters(path) {
  const paramIndex = path.indexOf('?');
  if (paramIndex === -1) {
    return path;
  }

  return path.slice(0, paramIndex);
}

/**
 * Whether the specified domain name and path belong to any of the alias domains (eg. set via setDomains).
 *
 * Note: this function is used to determine whether a click on a URL will be considered an "Outlink".
 *
 * @param {string} host
 * @param {string} path
 * @param {Array.<string>} hostAliases
 * @param {Object} content
 * @param {function(string): string} content.toAbsoluteUrl
 * @returns {boolean}
 */
export function isSiteHostPath(host, path, hostAliases, content) {
  for (let i = 0; i < hostAliases.length; i++) {
    const aliasHost = domainFixup(hostAliases[i]);
    const aliasPath = getPathName(hostAliases[i], content);

    if (isSameHost(host, aliasHost) && isSitePath(path, aliasPath)) {
      return true;
    }
  }

  return false;
}

/**
 *
 * @param {string} url
 * @param {string} trackerUrl
 * @returns {boolean}
 */
export function startsUrlWithTrackerUrl(url, trackerUrl) {
  if (!url || !trackerUrl) {
    return false;
  }
  return stringStartsWith(url, trackerUrl);
}

/**
 * Link or Download?
 * @param {string} className
 * @param {string} href
 * @param {boolean} isInLink
 * @param {boolean} hasDownloadAttribute
 * @param {Object} config
 * @param {string} config.configTrackerUrl
 * @param {string[]} config.configDownloadClasses
 * @param {string[]} config.configLinkClasses
 * @param {string[]} config.configDownloadExtensions
 * @returns {'download'|'link'|0}
 */
export function getLinkType(className, href, isInLink, hasDownloadAttribute, config) {
  if (startsUrlWith(href, config.configTrackerUrl)) {
    return 0;
  }

  // does class indicate whether it is an (explicit/forced) outlink or a download?
  const downloadPattern = getClassesRegExp(config.configDownloadClasses, 'download'),
    linkPattern = getClassesRegExp(config.configLinkClasses, 'link'),
    // does file extension indicate that it is a download?
    downloadExtensionsPattern = new RegExp(
      '\\.(' + config.configDownloadExtensions.join('|') + ')([?&#]|$)',
      'i'
    );

  if (linkPattern.test(className)) {
    return 'link';
  }

  if (
    hasDownloadAttribute ||
    downloadPattern.test(className) ||
    downloadExtensionsPattern.test(href)
  ) {
    return 'download';
  }

  if (isInLink) {
    return 0;
  }

  return 'link';
}

/**
 * @param {Element} sourceElement
 * @param {Object} content
 * @param {function(string): string} content.toAbsoluteUrl
 * @param {Object} config
 * @param {string} config.configTrackerUrl
 * @param {string[]} config.configDownloadClasses
 * @param {string[]} config.configLinkClasses
 * @param {string[]} config.configDownloadExtensions
 * @param {string[]} config.configHostsAlias
 * @returns {{type: 'download'|'link'|0, href: string} | undefined}
 */
export function getLinkIfShouldBeProcessed(sourceElement, content, config) {
  sourceElement = getSourceElement(sourceElement);

  if (!query.hasNodeAttribute(sourceElement, 'href')) {
    return;
  }

  if (!isDefined(sourceElement.href)) {
    return;
  }

  const href = query.getAttributeValueFromNode(sourceElement, 'href');

  if (startsUrlWith(href, config.configTrackerUrl)) {
    return;
  }

  const parsedHref = parseHref(href);

  const originalSourcePath = parsedHref.pathname || getPathName(href, content);

  // browsers, such as Safari, don't downcase hostname and href
  const originalSourceHostName = parsedHref.hostname || getHostName(parsedHref.href);
  const sourceHostName = originalSourceHostName.toLowerCase();
  const sourceHref = parsedHref.href.replace(originalSourceHostName, sourceHostName);

  // browsers, such as Safari, don't downcase hostname and href
  const scriptProtocol = new RegExp(
    '^(javascript|vbscript|jscript|mocha|livescript|ecmascript|mailto|tel|data|blob):',
    'i'
  );

  if (!scriptProtocol.test(sourceHref)) {
    // track outlinks and all downloads
    const linkType = getLinkType(
      sourceElement.className,
      sourceHref,
      isSiteHostPath(sourceHostName, originalSourcePath, config.configHostsAlias, content),
      query.hasNodeAttribute(sourceElement, 'download'),
      config
    );

    if (linkType) {
      return {
        type: linkType,
        href: sourceHref,
      };
    }
  }
}
