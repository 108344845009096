//@ts-check
import { validateEcommerceOptionsParameter } from './validateEcommerceOptionsParameter';
/** @import { EcommerceOptions } from '../../types.js' */

/**
 * @param {EcommerceOptions} options
 * @param {string} callerFunctionName
 */
export function validateTrackGoalOptionsParameter(options, callerFunctionName) {
  //  NOTE: this function is used both in ecommerce and goal tracking because
  //  parameters align. If they start drifting, we should create separate
  //  validator
  return validateEcommerceOptionsParameter(options, callerFunctionName);
}
