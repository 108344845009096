/**
 * Extract hostname from URL
 *
 * @param {string} url
 */
export function getHostName(url) {
  // scheme : // [username [: password] @] hostame [: port] [/ [path] [? query] [# fragment]]
  const e = new RegExp('^(?:(?:https?|ftp):)/*(?:[^@?]+@)?([^:/#]+)');
  const matches = e.exec(url);

  return matches ? matches[1] : url;
}

export function parseHref(hrefToParse) {
  const hrefParser = document.createElement('a');
  hrefParser.href = hrefToParse;
  return {
    href: hrefParser.href,
    hostname: hrefParser.hostname,
    pathname: hrefParser.pathname,
  };
}

/**
 * Remove query parameter from URL by name
 *
 * @param {string} url
 * @param {string} name
 * @returns {string}
 */
export function removeUrlParameter(url, name) {
  url = String(url);

  if (url.indexOf('?' + name + '=') === -1 && url.indexOf('&' + name + '=') === -1) {
    // nothing to remove, url does not contain this parameter
    return url;
  }

  const searchPos = url.indexOf('?');
  if (searchPos === -1) {
    // nothing to remove, no query parameters
    return url;
  }

  let queryString = url.substr(searchPos + 1);
  let baseUrl = url.substr(0, searchPos);

  if (queryString) {
    let urlHash = '';
    const hashPos = queryString.indexOf('#');
    if (hashPos !== -1) {
      urlHash = queryString.substr(hashPos + 1);
      queryString = queryString.substr(0, hashPos);
    }

    let param;
    const paramsArr = queryString.split('&');

    for (let i = paramsArr.length - 1; i >= 0; i--) {
      param = paramsArr[i].split('=')[0];
      if (param === name) {
        paramsArr.splice(i, 1);
      }
    }

    const newQueryString = paramsArr.join('&');

    if (newQueryString) {
      baseUrl = baseUrl + '?' + newQueryString;
    }

    if (urlHash) {
      baseUrl += '#' + urlHash;
    }
  }

  return baseUrl;
}

/**
 * Extract scheme/protocol from URL
 *
 * @param {string} url
 */

export function getProtocolScheme(url) {
  const e = new RegExp('^([a-z]+):');
  const matches = e.exec(url);

  return matches ? matches[1] : null;
}

/**
 * We do not check whether URL contains already url parameter, please use removeUrlParameter() if needed
 * before calling this method.
 * This method makes sure to append URL parameters before a possible hash. Will escape (encode URI component)
 * the set name and value
 * @param {string} url
 * @param {string} name
 * @param {string} value
 * @returns {string}
 */
export function addUrlParameter(url, name, value) {
  url = String(url);

  if (!value) {
    value = '';
  }

  const link = document.createElement('a');
  link.href = url;

  const params = link.search.split('&');
  params.push(encodeURIComponent(name) + '=' + encodeURIComponent(value));

  if (params[0] === '') {
    params.shift();
  }

  link.search = params.join('&');

  return link.href;
}

/**
 * @param {string} hostName
 * @param {string} alias
 * @returns {boolean}
 */
export function isSameHost(hostName, alias) {
  hostName = String(hostName).toLowerCase();
  alias = String(alias).toLowerCase();

  if (hostName === alias) {
    return true;
  }

  if (alias.slice(0, 1) === '.') {
    if (hostName === alias.slice(1)) {
      return true;
    }

    const offset = hostName.length - alias.length;

    if (offset > 0 && hostName.slice(offset) === alias) {
      return true;
    }
  }

  return false;
}

/**
 * Extract pathname from URL. element.pathname is actually supported by pretty much all browsers including
 * IE6 apart from some rare very old ones
 * @param {string} url
 * @param {Object} content
 * @param {function(string): string} content.toAbsoluteUrl
 * @returns {string}
 */
export function getPathName(url, content) {
  const parser = document.createElement('a');
  if (url.indexOf('//') !== 0 && url.indexOf('http') !== 0) {
    if (url.indexOf('*') === 0) {
      url = url.substr(1);
    }
    if (url.indexOf('.') === 0) {
      url = url.substr(1);
    }
    url = 'http://' + url;
  }

  parser.href = content.toAbsoluteUrl(url);

  if (parser.pathname) {
    return parser.pathname;
  }

  return '';
}

/**
 *
 * @param {string} url
 * @param {string} startUrl
 * @returns {boolean}
 */
export function startsUrlWith(url, startUrl) {
  return startUrl && url && 0 === String(url).indexOf(startUrl);
}

/**
 * Extract parameter from URL
 *
 * @param {string} url
 * @param {string} name
 * @returns {string}
 */
export function getUrlParameter(url, name) {
  const regexSearch = '[\\?&#]' + name + '=([^&#]*)';
  const regex = new RegExp(regexSearch);
  const results = regex.exec(url);
  return results ? decodeURIComponent(results[1]) : '';
}

/**
 * Removes hash tag from the URL
 *
 * URLs are purified before being recorded in the cookie,
 * or before being sent as GET parameters in the tracking request.
 *
 * @param {string} url
 * @param {Object} config
 * @param {boolean} config.discardHashTag
 * @param {string} config.visitorIdUrlParameter
 */
export function purify(url, config) {
  // we need to remove this parameter here, they wouldn't be removed in Piwik tracker otherwise eg
  // for outlinks or referrers
  url = removeUrlParameter(url, config.visitorIdUrlParameter);

  if (config.discardHashTag) {
    const targetPattern = new RegExp('#.*');

    return url.replace(targetPattern, '');
  }

  return url;
}

/**
 * Resolve relative reference
 *
 * Note: not as described in rfc3986 section 5.2
 * @param {string} baseUrl
 * @param {string} url
 * @param {Object} config
 * @param {boolean} config.discardHashTag
 * @param {string} config.visitorIdUrlParameter
 * @returns {string}
 *
 */
export function resolveRelativeReference(baseUrl, url, config) {
  const protocol = getProtocolScheme(url);

  if (protocol) {
    return url;
  }

  if (url.slice(0, 1) === '/') {
    return getProtocolScheme(baseUrl) + '://' + getHostName(baseUrl) + url;
  }

  baseUrl = purify(baseUrl, {
    discardHashTag: config.discardHashTag,
    visitorIdUrlParameter: config.visitorIdUrlParameter,
  });

  let i = baseUrl.indexOf('?');
  if (i >= 0) {
    baseUrl = baseUrl.slice(0, i);
  }

  i = baseUrl.lastIndexOf('/');
  if (i !== baseUrl.length - 1) {
    baseUrl = baseUrl.slice(0, i + 1);
  }

  return baseUrl + url;
}
