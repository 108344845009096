export const flatHintMap = {
  model: 'ch_m',
  brands: 'ch_b',
  platform: 'ch_p',
  platformVersion: 'ch_pv',
  fullVersionList: 'ch_ufv',

  // The following parameters are returned by the browser but are not supported at the moment.
  // wow64: 'w64',
  // mobile: 'ch_mb',
  // bitness: 'ch_bt',
  // formFactor: 'ch_ff',
  // architecture: 'ch_a',
  // uaFullVersion: 'ch_uafv',
};

/**
 * Converts a hint to a string and encodes it
 * @param {string | object} hint
 * @returns {string}
 */
const convertHintValue = (hint) => {
  if (!hint) {
    return '';
  }

  const hintValue = typeof hint === 'object' ? JSON.stringify(hint) : hint;

  return window.encodeURIComponent(hintValue);
};

/**
 *  Converts a hints object into a flat URL query string.
 * @param {Object} hints
 * @param {string} hints.model
 * @param {string} hints.platform
 * @param {string} hints.platformVersion
 * @param {{ brand: string; version: string }[]} hints.brands
 * @param {{ brand: string; version: string }[]} hints.fullVersionList
 * @returns {string}
 */
export const convertHintsToFlatUrlStructure = (hints) => {
  let result = '';

  if (typeof hints === 'object') {
    for (const hintKey in hints) {
      if (
        Object.prototype.hasOwnProperty.call(hints, hintKey) &&
        flatHintMap[hintKey] &&
        hints[hintKey]
      ) {
        result += `&${flatHintMap[hintKey]}=${convertHintValue(hints[hintKey])}`;
      }
    }
  }

  return result;
};
