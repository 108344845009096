import { isFunction } from '../types';

/**
 * Browser features (plugins, resolution, cookies)
 * @param {function(): '1' | '0'} hasCookies
 * @returns {Object.<string, string>}
 */
export function detectBrowserFeatures(hasCookies) {
  /**
   * Browser features via client-side data collection
   * @type {Object.<string, string>}
   */
  const browserFeatures = {};
  // detect browser features except IE < 11 (IE 11 user agent is no longer MSIE)
  if (!new RegExp('MSIE').test(navigator.userAgent)) {
    // general plugin detection
    if (navigator.mimeTypes && navigator.mimeTypes.length) {
      for (const i in PLUGINS_MAP) {
        if (Object.prototype.hasOwnProperty.call(PLUGINS_MAP, i)) {
          const mimeType = navigator.mimeTypes[PLUGINS_MAP[i]];
          browserFeatures[i] = mimeType && mimeType.enabledPlugin ? '1' : '0';
        }
      }
    }

    // Safari and Opera
    // IE6/IE7 navigator.javaEnabled can't be aliased, so test directly
    if (isFunction(navigator.javaEnabled) && navigator.javaEnabled()) {
      browserFeatures.java = '1';
    }

    // Firefox
    if (isFunction(window.GearsFactory)) {
      browserFeatures.gears = '1';
    }

    // other browser features
    browserFeatures.cookie = hasCookies();
  }

  var width = parseInt(screen.width, 10);
  var height = parseInt(screen.height, 10);
  browserFeatures.res = width + 'x' + height;
  return browserFeatures;
}

const PLUGINS_MAP = Object.freeze({
  // document types
  pdf: 'application/pdf',

  // media players
  qt: 'video/quicktime',
  realp: 'audio/x-pn-realaudio-plugin',
  wma: 'application/x-mplayer2',

  // interactive multimedia
  dir: 'application/x-director',
  fla: 'application/x-shockwave-flash',

  // RIA
  java: 'application/x-java-vm',
  gears: 'application/x-googlegears',
  ag: 'application/x-silverlight',
});
