export function transformIfAMPCookieFactory() {
  const mapping = [
    [/g/g, '10'],
    [/h/g, '11'],
    [/i/g, '12'],
    [/j/g, '13'],
    [/k/g, '14'],
    [/l/g, '15'],
    [/m/g, '16'],
    [/n/g, '17'],
    [/o/g, '18'],
    [/p/g, '19'],
    [/q/g, '1a'],
    [/r/g, '1b'],
    [/s/g, '1c'],
    [/t/g, '1d'],
    [/u/g, '1e'],
    [/v/g, '1f'],
    [/w/g, '20'],
    [/x/g, '21'],
    [/y/g, '22'],
    [/z/g, '23'],
    [/_/g, '3e'],
    [/-/g, '3f'],
  ];

  const AMPCookieRegex = /^[0-9a-f]{16}$/;

  /**
   * @param {string} id
   * @returns {string}
   */
  return (id) => {
    if (id && !AMPCookieRegex.test(id)) {
      id = id.toLowerCase().substr(4, 16);
      for (let i = 0; i < mapping.length; i += 1) {
        id = id.replace(mapping[i][0], mapping[i][1]);
      }

      id = id.substr(0, 16);
    }

    return id;
  };
}

/**
 * @param {string} cookieName
 * @param {string} value
 * @param {Object} config
 * @param {number} [config.msToExpire]
 * @param {string} [config.path]
 * @param {string} [config.domain]
 * @param {boolean} [config.isSecure]
 * @returns {string}
 */
export function composeCookie(cookieName, value, config) {
  let expiryDate;

  // relative time to expire in milliseconds
  if (config.msToExpire) {
    expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + config.msToExpire);
  }

  return (
    cookieName +
    '=' +
    value +
    (config.msToExpire ? ';expires=' + expiryDate.toGMTString() : '') +
    ';path=' +
    (config.path || '/') +
    (config.domain ? ';domain=' + config.domain : '') +
    (config.isSecure ? ';secure' : '') +
    ';SameSite=Lax'
  );
}
